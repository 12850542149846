<template>
  <v-row class="d-flex" justify="space-between">
    <v-col cols="12" sm="6" md="8">
      <v-row>
        <v-col cols="auto">
          <v-menu
            ref="menuStartDate"
            v-model="menuStartDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                outlined
                class="caption"
                :placeholder="$_strings.common.FROM_DATE"
                :value="dayjs(filter.accrualDateFrom).format('DD-MM-YYYY')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filter.accrualDateFrom"
              no-title
              locale="id"
              :max="filter.accrualDateTo"
              @change="menuStartDate = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-none d-sm-flex align-center px-0" cols="auto">-</v-col>
        <v-col cols="auto">
          <v-menu
            ref="menuEndDate"
            v-model="menuEndDate"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                class="caption"
                outlined
                :value="dayjs(filter.accrualDateTo).format('DD-MM-YYYY')"
                :placeholder="$_strings.common.TO_DATE"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="filter.accrualDateFrom"
              v-model="filter.accrualDateTo"
              no-title
              @change="menuEndDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="5" md="4">
      <v-text-field
        dense
        hide-details
        outlined
        class="caption"
        ref="search"
        @keyup.enter="handleSearch"
        placeholder="Cari No Jurnal"
        v-model="search"
      >
        <template v-slot:prepend-inner>
          <v-icon @click="handleSearch">mdi-magnify </v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'filter-list',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: this.$route.query.journalNumber || '',
      menuStartDate: false,
      menuEndDate: false,
    };
  },
  watch: {
    'filter.accrualDateFrom': function accrualDateFrom(newVal, oldVal) {
      if (this.$route.query.accrualDateFrom !== newVal) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            accrualDateFrom: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('refetch');
      }
    },
    'filter.accrualDateTo': function settoDate(newVal, oldVal) {
      if (this.$route.query.accrualDateTo !== newVal) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            accrualDateTo: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('refetch');
      }
    },
  },
  methods: {
    dayjs,
    handleSearch() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          journalNumber: this.search,
          page: 1,
        },
      });
      this.filter.journalNumber = this.search;
      this.$emit('refetch');
    },
  },
};

</script>
