<template>
  <v-container fluid>
    <FilterList
      :filter="filter"
      @refetch="refetch"
      class="my-4"
    />
    <TableList
      :key="keyList"
      :filter="filter"
    />
  </v-container>
</template>
<script>
import dayjs from 'dayjs';
import TableList from './TableList';
import FilterList from './FilterList';

export default {
  components: {
    TableList,
    FilterList,
  },
  data() {
    const accrualDateFrom = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
    const accrualDateTo = dayjs().format('YYYY-MM-DD');
    return {
      keyList: 0,
      filter: {
        journalNumber: this.$route.query.journalNumber || '',
        accrualDateFrom: this.$route.query.accrualDateFrom || accrualDateFrom,
        accrualDateTo: this.$route.query.accrualDateTo || accrualDateTo,

      },
    };
  },
  methods: {
    dayjs,
    refetch() {
      this.keyList += 1;
    },
  },
};
</script>
