<template>
  <section>
    <v-data-table
      calculate-widths
      :loading="isLoading"
      :headers="headers"
      :items="items"
      :server-items-length="totalData"
      :options.sync="pagination"
      :footer-props="{
        showCurrentPage: true,
        showFirstLastPage: true,
        'items-per-page-options': $_item_per_page
      }"
    >
      <template v-slot:[`item.actions`]={item}>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              small
              icon
              @click="() => showDialog(item)"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.DETAIL}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <DialogFormObjectOracle ref="dialogFormObjectOracle" />
  </section>
</template>
<script>
import {
  handleSortBy,
  handlerPagination,
  defaultPagination,
  skipEmptyStringObject,
} from '@/helper/commonHelpers';
import DialogFormObjectOracle from './Dialog/FormObjectOracle';

export default {
  components: {
    DialogFormObjectOracle,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    source: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: 'Nama Shipper',
          value: 'customerName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Asal',
          value: 'originLocationName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Tujuan',
          value: 'destinationLocationName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'No Jurnal',
          value: 'journalNumber',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'No Pesanan',
          value: 'transactionNumber',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'No Kendaraan',
          value: 'vehicleNo',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Amount CR',
          value: 'amountCr',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Amount DR',
          value: 'amountDr',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'actions',
          class: 'white--text primary text-capitalize',
        },
      ],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    async showDialog(item) {
      const res = await this.fetchDetail(item.id);
      this.$refs.dialogFormObjectOracle.dialog = true;
      this.$refs.dialogFormObjectOracle.form.id = item.id;
      this.$refs.dialogFormObjectOracle.form.jsonBody = JSON.stringify(res.data, null, 2);
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const {
          page, itemsPerPage: size, sortBy, sortDesc,
        } = this.pagination;
        const filter = skipEmptyStringObject({
          ...this.filter,
          page: page - 1,
          size,
          sort: handleSortBy({ sortBy, sortDesc }),
        });
        const result = await this.$_services.oracleData.getListAccrualFromOracle({ filter });
        this.items = result.data.contents;
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async resendOracleCode(item) {
      try {
        this.$set(item, 'isLoading', true);
        await this.$_services.masterCustomer.resendOracleCode(item.id);
        this.$dialog.notify.success('Berhasil mengirim ulang ke Oracle');
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
    async fetchDetail(id) {
      try {
        this.$root.$loading.show();
        const result = await await this.$_services.oracleData.getDetailAccrualFromOracle({ id });
        return result;
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
